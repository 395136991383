@import "../../../app/styles/vars";

.edit {
  cursor: pointer;
  svg {
    color: $cold-gray;
  }
}

:global(.edit_dropdown) {
  max-width: 250px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(4, 59, 129, 0.24);
  border-radius: 4px;
  :global(.ant-dropdown-menu-item) {
    display: flex;
    align-items: center;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $cold-dark-gray;
    }
  }
  svg {
    color: $platina;
    margin-right: 11px;
  }
}
