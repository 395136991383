@import "@app/styles/vars.scss";

.header {
  //position: sticky;
  //top: 0;
  padding-top: 12px;
  padding-bottom: 16px;
  background-color: $your-highness;
  z-index: 1060;
  @media (max-width: 1065px) {
    padding-bottom: 30px;
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    :global(.form-item) {
      width: 100%;
      max-width: 400px;
    }
    @media (max-width: 1065px) {
      flex-direction: column;
      gap: 10px;
    }
    :only-child {
      // margin: 0 0 0 auto;
    }
  }
}

.breadcrumbs {
  margin-bottom: 16px;
}


.filename {
  @media (max-width: 1065px) {
    width: 100%;
  }
  :global(.ant-input-affix-wrapper) {
    border: none;
    border-radius: unset;
    background-color: unset !important;
    border-bottom: 1px solid $black-50;
    padding: 0;
    &:hover {
      border-bottom-color: $black-50;
    }
    :global(.ant-input) {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $text;
      background-color: unset;
    }
  }
  :global(.ant-input-affix-wrapper-focused) {
    box-shadow: none !important;
  }
  :global(.ant-input:disabled) {
    opacity: 0.3;
  }
}

.control {
  display: flex;
  flex-wrap: wrap;
  > *:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 15px;
  }
}
.segment {
  height: 44px;
  align-items: center;
  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .icon {
    height: 30px;
  }
  :global(.ant-segmented-item-label) {
    height: 40px;
    line-height: 40px;
  }
}
.dropdownButton {
  width: auto;
}
